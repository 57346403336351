function formatCNPJ(cnpj) {
    if(cnpj){
        // Remove non-numeric characters
    cnpj = cnpj.replace(/\D/g, '');
  
    // Insert dots and slashes into the formatted string
    return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    }
}

export default formatCNPJ